<script lang="ts">
  import Text from '$lib/components/text/text.svelte';
  import type { BlogPost } from '$lib/contentful/models/blog';
  import { highlightText } from '$lib/utilities/highlight-text';
  import BlogAuthor from '../blog-author/blog-author.svelte';
  import BlogCategories from '../blog-categories/blog-categories.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import type { SearchResult } from '../search/search.svelte';

  // maintain a 16:9 ratio
  const imgWidth = 16 * 30;
  const imgHeight = 9 * 30;

  export let post: SearchResult<BlogPost>;
  export let currentQuery: string | null = null;

  $: ({
    title,
    categories,
    featureImage,
    publishDate,
    person,
    slug,
    matchedFields,
    content,
    author,
  } = post);
</script>

<div class="card" aria-label="Blog Card {slug}">
  <a href="/blog/{slug}">
    <ContentfulImage
      class="rounded-t-2xl cursor-pointer aspect-[16/9]"
      width={imgWidth}
      height={imgHeight}
      src={featureImage?.url}
      alt={featureImage?.title}
      fitStrategy="fill"
    />
  </a>
  <div class="card-content">
    <BlogCategories {categories} {publishDate} />
    <a href="/blog/{slug}">
      {#if matchedFields?.includes('title') && currentQuery}
        <RichText
          class="text-3xl leading-8"
          theme="white"
          text={highlightText(title, currentQuery)}
        />
      {:else}
        <Text variant="heading-32" class="cursor-pointer">
          {title}
        </Text>
      {/if}
    </a>

    {#if matchedFields?.includes('content') && currentQuery}
      <a href="/blog/{slug}">
        <RichText
          class="text-lg"
          theme="dark"
          text={highlightText(content, currentQuery)}
        />
      </a>
    {/if}
    <BlogAuthor
      {author}
      {person}
      {currentQuery}
      highlightAuthor={matchedFields?.includes('author')}
    />
  </div>
</div>

<style lang="postcss">
  .card {
    @apply max-w-[30rem] bg-space-black border border-graphite text-white hover:text-indigo-100 transition-colors flex flex-col rounded-2xl;
  }

  .card-content {
    @apply p-4 flex grow flex-col justify-between gap-8 rounded-b-2xl;
  }
</style>
